.overlay2{
    position: absolute;
  top: 7.4%;
  bottom: 0;
  left: 7.4%;
  right: 0;
  height: 85%;
  width: 85%;
  opacity: 1;
  
  background-color: #fff;
 border-radius: 100%;
  border:none;
 
 }
 
 @media (max-width: 900px){
 .overlay3{
    position: absolute;

  left: 7.4%; 

  height: 40px;
  width: 40px;
  opacity: 1;
 
  background-color: #fff;
 border-radius: 100%;
 
 }
}



 @media (min-width: 992px){
 .overlay3{
    position: absolute;
   
    left: -webkit-calc(100% - 44.5px)!important;  
 

  height: 40px;
  width: 40px;
  opacity: 1;
 
  background-color: #fff;

  border:none;
  border-radius: 100%;
 }

 }

 @media (min-width: 900px) and (max-width:960px){
    .overlay3{
       position: absolute;
      
        
    
      left: -webkit-calc(100% - 41.5px)!important;  
     

    
     height: 40px;
     width: 40px;
     opacity: 1;
    
     background-color: #fff;
   
     border:none;
     border-radius: 100%;
    }
   
    }

    @media (min-width: 960px) and (max-width:992px){
        .overlay3{
           position: absolute;
          
            
        
          left: -webkit-calc(100% - 44.5px)!important;  
         
    
        
         height: 40px;
         width: 40px;
         opacity: 1;
        
         background-color: #fff;
       
         border:none;
         border-radius: 100%;
        }
       
        }

        

      
      @media (min-width: 643px) and (max-width: 814px){
         .mediaBen4{
             width: 43% !important;
         }
         }

         .imagoMundi3{
            background-color: gainsboro!important;
             border-width: 4px!important;
             width:auto!important;
              height: 5rem!important; 
             /* object-fit: content-fit!important; */ 
             /* width: 100%!important; 
             height:8rem!important; */
             max-height: 5rem!important;
             /* max-width: 5rem!important; */
             
             /* object-fit: scale-down!important; */
         }

         .aparecer:hover{
            opacity:1!important;
            background-color:#fff!important
         }

      /*    .hovereoAv:hover{
            border:0px!important;
         }

         .iconeo:hover ~ .hovereoAv{
            border:0px!important;
         } */

         .slider{
            display: block!important;
            width: auto!important;
            height: calc(5rem+4px)!important; 
  object-fit: cover!important;
         }

         .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction{
            bottom: auto!important;
         }

         .swiper-button-next{
            color: #004e56 !important;
            right: 0%!important;
          top: 40% !important;
         }

         .swiper-button-next:after, .swiper-button-prev:after {
            font-size: 30px!important;
         }

         .swiper-button-prev{
            color: #004e56 !important;
            left: 0%!important;
            top: 40% !important;
         }


      .swiper-pagination-bullet-active{
         background-color: #004e56 !important;
      }


.swiper-pagination{
   position: relative !important;
}

.swiper-slide2 {
   text-align: center;
   font-size: 18px;
   background: #fff;
 
   /* Center slide text vertically */
   display: -webkit-box;
   display: -ms-flexbox;
   display: -webkit-flex;
   display: flex;
   -webkit-box-pack: center;
   -ms-flex-pack: center;
   -webkit-justify-content: center;
   justify-content: center;
   -webkit-box-align: center;
   -ms-flex-align: center;
   -webkit-align-items: center;
   align-items: center;
   /* margin-right: 30px; */
 }
 
 .swiper-slide2 img {
   display: block;
   width: 100%;
   height: 100%;
   object-fit: cover;
 }

 .swiper-wrapper{
   /*  margin-left:30px!important; */
   height:100%;
   /* -webkit-transition-timing-function:linear!important; 
  -o-transition-timing-function:linear!important;
  transition-timing-function:linear!important;  */
 }

 .swiper-image {
   display: block;
   width: auto;
   height: 5rem;
   object-fit: cover;
 }

 .divdentro{
   border: 4px solid white;
   border-radius: 4px;
 }


 .slidereocard{
   display: block!important;
   width: auto!important;
   height: 100px!important; 
object-fit: cover!important;
 }

 .slidereocard img{
   display: block;
   width: 100%;
   height: 100%;
   object-fit: cover;
 }

 .mySwipereoEmp{
   margin: 0px !important;
 }
 .conteneque .swiper-wrapper{
   margin: 0px !important;
 }

/*  .mobileStepp{
   position: absolute !important;
 } */

.hoverflechafoto:hover{
opacity: 1 !important;
background-color:#fff!important
}



.gridoarriba .MuiMobileStepper-dot{
   background-color: rgba(0, 0, 0, 0.35);
   }
   .gridoarriba .MuiMobileStepper-dotActive{
      background-color: gainsboro;
      }

@media (max-width:959.9px) {
   .contieneImagenEmpresa{
      max-height: 300px!important;
   }
}

@media (min-width: 960px){
   .otro1{
      /*  width:28.42rem!important; */
        margin-right: 1.6rem!important;
   }
   }


   .cuadroUsuario{
      display:"flex"
   }


   
   .noven{
      width: 100%;
      max-width: 200px!important;
      margin-top: 1rem!important;
      display: inline-flex;
      align-items: center!important;
       justify-content: center!important;
      /* position: relative; */
      box-sizing: border-box;
      outline: 0px;
        border: 0px;
        margin: 0px;
    font-weight: 600!important;
        font-size: 14px;
        line-height: 1.75;
        min-width: 64px!important;
        padding: 8px 22px;
        border-radius: 28px!important;
        color: rgba(0, 0, 0, 0.87)!important;
        background-color: #f9b934!important;
        box-shadow: none;
    }

    .noven:hover{
      background-color: #FF9800!important;
     
  }

  @media (max-width: 600px){
   .noven{
     max-width: 100%!important;
     margin-top: 40px!important;
     
  }

  

  }

  .prueeb2{
   position: absolute!important; 
   /* top:+70px!important; */
   top:+20px!important;
   right:+1.6rem!important;
}

@media (min-width: 992px){
.prueeb2{
 /*  margin-top: -4 rem!important; */
  position: relative!important;
  top:-250px!important;
  /* top:-200px!important; */
  /* left:-12%!important; */
  left:-40px!important;
/*     left:-30px!important; */
}
}

@media (max-width:991.9px){
   .margenotravez2{
       margin-bottom: 24px!important;
   }
}

.eliminaDisable{
   cursor:not-allowed!important;
   background-color: #fff!important;
}

.seconReenv{
   cursor: pointer!important;
   color:#677788!important;
   width:130px!important;
   font-weight: 500!important;
   font-size: 0.875rem!important;
}

.seconReenv:hover{
   color: #004e56!important;
}

@media (min-width: 992px){
   .oculteroEmp{
      margin-top:0px!important;
      padding-left:0px!important;
   }
}

.desabMenu{
   background-color: transparent!important;
}

.formeEmp{
   width:140px !important;
}

.formeEmp .Mui-disabled{
   background-color: transparent!important;
   cursor:auto !important;
  
}

.sinBorde .formeEmp .MuiOutlinedInput-notchedOutline{
   border:0 !important; 
  
}

.sinBorde .formeEmp .MuiSvgIcon-root{
   color:transparent !important; 
  
}

.formeEmp .MuiSelect-select{
   text-align: end;
   padding-left: 0px;
   padding-right: 0px;
}

.formeEmp2{
   width:140px !important;
}

.formeEmp2 .Mui-disabled{
   background-color: #fff!important;
   cursor:not-allowed !important;
  color:rgba(0, 0, 0, 0.54)!important;
}

.sinBorde .formeEmp2 .MuiOutlinedInput-notchedOutline{
   border:0 !important; 
  
}

/* .formeEmp2 .MuiSelect-nativeInput{
   color:rgba(0, 0, 0, 0.54)!important;
} */

.sinBorde .formeEmp2 .MuiSvgIcon-root{
   color:#fff !important; 
  
}

.formeEmp2 .MuiSelect-select{
   text-align: end;
   padding-left: 0px;
   padding-right: 0px;

 -webkit-text-fill-color: black!important;

}

@media (min-width: 600px){
.padLeft{
 padding-left: 16px!important;
}
}

